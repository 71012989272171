import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Layout from '@/Layout';
import CompanyAccepted from '@/pages/CompanyAccepted';
import CompanyConfirmation from '@/pages/CompanyConfirmation';
import CompanyInvoices from '@/pages/CompanyInvoices';
import ErrorPage from '@/pages/ErrorPage';
import Home from '@/pages/Home';
import Maintenance from '@/pages/Maintenance';
import NotFound from '@/pages/NotFound';
import Payment from '@/pages/Payment';
import PaymentAccepted from '@/pages/PaymentAccepted';
import PaymentConfirmation from '@/pages/PaymentConfirmation';

const App = () : JSX.Element => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
            },
        },
    });

    if (import.meta.env.VITE_APP_MAINTENANCE) {
        return <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="*" element={<Maintenance/>}/>
                </Route>
            </Routes>
        </BrowserRouter>;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<Home/>}/>
                            <Route
                                path="company/:companyId/invoices"
                                element={<CompanyInvoices/>}
                            />
                            <Route
                                path="company/:companyId/accept"
                                element={<CompanyAccepted/>}
                            />
                            <Route
                                path="company/:companyId/confirm/:paymentId"
                                element={<CompanyConfirmation/>}
                            />
                            <Route
                                path="invoice/:companyId/:billId"
                                element={<Payment billType={'invoice'}/>}
                            />
                            <Route
                                path="invoice/:companyId/:billId/accept"
                                element={<PaymentAccepted billType={'invoice'}/>}
                            />
                            <Route
                                path="invoice/:companyId/:billId/confirm"
                                element={<PaymentConfirmation billType={'invoice'}/>}
                            />
                            <Route
                                path="quote/:companyId/:billId"
                                element={<Payment billType={'quote'}/>}
                            />
                            <Route
                                path="quote/:companyId/:billId/accept"
                                element={<PaymentAccepted billType={'quote'}/>}
                            />
                            <Route
                                path="quote/:companyId/:billId/confirm"
                                element={<PaymentConfirmation billType={'quote'}/>}
                            />
                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default App;
