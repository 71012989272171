import {useQuery} from '@tanstack/react-query';
import type {QueryClient, UseQueryResult} from '@tanstack/react-query';
import {z} from 'zod';
import {billSchema} from '@/utils/Invoice';
import type {Bill} from '@/utils/Invoice';
import {convertFromFmToJsDate, hasTypeOfError} from '@/utils/Utils';

export type Quote = Bill & {
    quoteUuidForStripe : string;
    quoteNumber : number;
    // fm field: DateInvoice; note: Invoice has dueDate instead of quoteDate
    quoteDate : Date;
};

export const quoteSchema = billSchema.extend({
    quoteUuidForStripe: z.string(),
    quoteNumber: z.number(),
    quoteDate: z.string(),
});

export type InferredQuote = z.infer<typeof quoteSchema>;

const useInvalidateQuoteCache = async (
    queryClient : QueryClient,
    companyId : string,
    quoteId : string
) : Promise<void> => {
    await queryClient.invalidateQueries(['quote', companyId, quoteId]);
};

const fetchQuote = async (route : string) : Promise<InferredQuote> => {
    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    try {
        const response = await fetch(`${baseUrl}${route}`);
        const rawData : unknown = await response.json();

        if (!response.ok) {
            console.log('fetchQuote: response:', response);
            console.log('fetchQuote: rawData:', rawData);
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = quoteSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        console.error('fetchQuote error:', error);
        throw new Error(
            'Failed to retrieve quote. If this persists, please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL}.`
        );
    }
};

const useQuoteQuery = (companyId : string | undefined, quoteId : string | undefined)
: UseQueryResult<Quote | undefined, Error> => {
    return useQuery(
        ['quote', companyId, quoteId],
        async () : Promise<Quote> => {
            if (!companyId || !quoteId) {
                throw new Error('useQuoteQuery: Missing required ID');
            }

            const data = await fetchQuote(`/quote/${companyId}/${quoteId}`);
            console.log('useQuoteQuery data:', data);
            return {
                ...data,
                quoteDate: convertFromFmToJsDate(data.quoteDate),
            };
        }
    );
};

export {useQuoteQuery, useInvalidateQuoteCache, fetchQuote};
