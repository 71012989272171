import {Grid} from '@mui/material';
import type {Invoice} from '@/utils/Invoice';
import type {Quote} from '@/utils/Quote';
import {formatAsCurrency} from '@/utils/Utils';

type Props = {
    bill : Invoice | Quote;
    billType : string;
};

const PaymentsMade = ({bill, billType = 'invoice'} : Props) : JSX.Element => {
    const billTypeCapitalized = billType[0].toUpperCase() + billType.substring(1);
    return (
        <>
            {
                bill.payments.length > 0 && bill.payments.map(payment => (
                    <Grid container key={payment.paymentIntentId} sx={{
                        pt: bill.payments.length === 1 ? 0 : 1,
                    }}>
                        <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Payment date</Grid>
                        <Grid item xs={12} md={10}>{payment.paymentDate}</Grid>
                        <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>{billTypeCapitalized} amount</Grid>
                        <Grid item xs={12} md={10}>{formatAsCurrency(payment.amount)}</Grid>
                        <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Processing fee</Grid>
                        <Grid item xs={12} md={10}>
                            {formatAsCurrency(payment.soliantTransactionFee)}
                        </Grid>
                    </Grid>
                ))
            }
        </>
    );
};

export default PaymentsMade;
