import {Card, Typography} from '@mui/material';
import SoliantContainer from '@/components/SoliantContainer';

const NotFound = () : JSX.Element => {
    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                <Typography variant='h6'>Not found</Typography>
            </Card>
        </SoliantContainer>
    );
};

export default NotFound;
