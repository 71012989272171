import {ThemeProvider} from '@mui/material/styles';
import theme from '../theme';

type Props = {
    children : JSX.Element;
};

const ThemeComponent = ({children} : Props) : JSX.Element => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <main style={{
                    backgroundColor: '#327a97',
                    minHeight: '100vh',
                }}>
                    {children}
                </main>
            </ThemeProvider>
        </>
    );
};

export default ThemeComponent;
