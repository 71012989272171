import {useQuery} from '@tanstack/react-query';
import type {QueryClient, UseQueryResult} from '@tanstack/react-query';
import {z} from 'zod';
import {convertFromFmToJsDate, hasTypeOfError} from './Utils';

const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

export type Bill = {
    companyUuidForStripe : string;
    companyName : string;
    amountDue : number;
    amountDueWithSoliantTransactionFee : number;
    soliantTransactionFeeDue : number;
    soliantTransactionFeePaid : number;
    amountPaidWithSoliantTransactionFee : number;
    clientSecretForCard ?: string;
    clientSecretForOther ?: string;
    payments : Array<{
        paymentIntentId ?: string;
        paymentDate ?: string;
        amount : number;
        soliantTransactionFee : number;
    }>;
};

export type Invoice = Bill & {
    invoiceUuidForStripe : string;
    invoiceNumber : string;
    // note: Quote has quoteDate instead of dueDate
    dueDate : Date;
};

// Type guard for Invoice
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const hasTypeOfInvoice = (obj : any) : obj is Invoice => {
    return !!obj
        && typeof obj === 'object'
        && 'invoiceUuidForStripe' in obj;
};

export const billSchema = z.object({
    companyUuidForStripe: z.string(),
    companyName: z.string(),
    amountDue: z.number(),
    amountDueWithSoliantTransactionFee: z.number(),
    soliantTransactionFeeDue: z.number(),
    soliantTransactionFeePaid: z.number(),
    amountPaidWithSoliantTransactionFee: z.number(),
    clientSecretForCard: z.string().optional(),
    clientSecretForOther: z.string().optional(),
    payments: z.array(z.object({
        paymentIntentId: z.string().optional(),
        paymentDate: z.string().optional(),
        amount: z.number(),
        soliantTransactionFee: z.number(),
    })),
});

export const invoiceSchema = billSchema.extend({
    invoiceUuidForStripe: z.string(),
    invoiceNumber: z.string(),
    dueDate: z.string(),
});

export type InferredInvoice = z.infer<typeof invoiceSchema>;

const useInvalidateInvoiceCache = async (
    queryClient : QueryClient,
    companyId : string,
    invoiceId : string
) : Promise<void> => {
    await queryClient.invalidateQueries(['invoice', companyId, invoiceId]);
};

const fetchInvoice = async (route : string) : Promise<InferredInvoice> => {
    try {
        const response = await fetch(`${baseUrl}${route}`);
        const rawData : unknown = await response.json();

        if (!response.ok) {
            console.log('fetchInvoice: response:', response);
            console.log('fetchInvoice: rawData:', rawData);
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = invoiceSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        console.error('fetchInvoice error:', error);
        throw new Error(
            'Failed to retrieve invoice. If this persists, please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL}.`
        );
    }
};

export const fetchInvoicePdf = async (companyId : string, invoiceId : string) : Promise<Response> => {
    return fetch(`${baseUrl}/invoice/${companyId}/${invoiceId}/download`);
};

const useInvoiceQuery = (companyId : string | undefined, invoiceId : string | undefined)
: UseQueryResult<Invoice | undefined, Error> => {
    return useQuery(
        ['invoice', companyId, invoiceId],
        async () : Promise<Invoice> => {
            if (!companyId || !invoiceId) {
                throw new Error('useInvoiceQuery: Missing required ID');
            }

            const data = await fetchInvoice(`/invoice/${companyId}/${invoiceId}`);
            // console.log('useInvoiceQuery data:', data);
            return {
                ...data,
                dueDate: convertFromFmToJsDate(data.dueDate),
            };
        }
    );
};

export {useInvoiceQuery, useInvalidateInvoiceCache, fetchInvoice};
