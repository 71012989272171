import {z} from 'zod';
import {convertFromFmToJsDate, hasTypeOfError} from '@/utils/Utils';

const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

export const companySchema = z.object({
    name: z.string(),
    invoices: z.array(z.object({
        stripeUuid: z.string(),
        dueDate: z.string(),
        totalDue: z.number(),
        type: z.string(),
        totalInvoiceAmount: z.number(),
        ProjectName: z.string(),
        invoiceID: z.string(),
    })),
    quotes: z.array(z.object({
        stripeUuid: z.string(),
        quoteDate: z.string(),
        totalDue: z.number(),
        totalQuoteAmount: z.number(),
        quoteId: z.number(),
    })),
});

export type Company = {
    name : string;
    invoices : Array<{
        invoiceID : string;
        stripeUuid : string;
        dueDate : Date;
        totalDue : number;
        type : string;
        totalInvoiceAmount : number;
        ProjectName : string;
    }>;
    quotes : Array<{
        stripeUuid : string;
        quoteDate : Date;
        totalDue : number;
        totalQuoteAmount : number;
        quoteId : number;
    }>;
};

export const companyPayInvoicesSchema = z.object({
    totalDue: z.number(),
    totalDueWithFee: z.number(),
    card: z.object({
        clientSecret: z.string(),
        setupId: z.string(),
    }),
    bank: z.object({
        clientSecret: z.string(),
        setupId: z.string(),
    }),
});

export type PayInvoices = z.infer<typeof companyPayInvoicesSchema>;

export const fetchCompanySetupIntents = async (
    companyId : string,
    invoiceIds : string[]
) : Promise<PayInvoices> => {
    try {
        const response = await fetch(
            `${baseUrl}/company/${companyId}/generate-setup-intents`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    invoiceIds,
                }),
            }
        );
        const rawData : unknown = await response.json();

        if (!response.ok) {
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = companyPayInvoicesSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        console.error('fetchInvoice error:', error);
        throw new Error(
            'Failed to retrieve company. If this persists, please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL}.`
        );
    }
};

export const fetchCompany = async (companyId : string) : Promise<Company> => {
    try {
        const response = await fetch(`${baseUrl}/company/${companyId}/`);
        const rawData : unknown = await response.json();

        if (!response.ok) {
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = companySchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return {
            name: data.data.name,
            invoices: data.data.invoices.map(i => ({
                ...i,
                dueDate: convertFromFmToJsDate(i.dueDate),
            })).sort(
                (a, b) => {
                    if ((a.totalDue != 0 || b.totalDue != 0) && (a.totalDue === 0 || b.totalDue === 0)) {
                        return b.totalDue - a.totalDue;
                    }

                    return a.dueDate < b.dueDate ? 1 : -1;
                }
            ),
            quotes: data.data.quotes.map(q => ({
                ...q,
                quoteDate: convertFromFmToJsDate(q.quoteDate),
            })),
        };
    } catch (error) {
        console.error('fetchInvoice error:', error);
        throw new Error(
            'Failed to retrieve company. If this persists, please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL}.`
        );
    }
};

export const companyAcceptSchema = z.object({
    status: z.string(),
    payment: z.string().optional(),
    errorMessage: z.string().optional(),
    extra: z.string().optional(),
});

export type CompanyAccept = z.infer<typeof companyAcceptSchema>;

export const fetchAcceptCompanySetupIntents = async (
    companyId : string,
    paymentIntentId : string
) : Promise<CompanyAccept> => {
    try {
        const response = await fetch(
            `${baseUrl}/company/${companyId}/accept-setup-intent`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentIntentId,
                }),
            }
        );
        const rawData : unknown = await response.json();

        if (!response.ok) {
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = companyAcceptSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        console.error('fetchInvoice error:', error);
        throw new Error(
            'Failed to accept payment intent. Please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL} to check if the payment went through.`
        );
    }
};

export const companyPaymentSchema = z.object({
    invoiceNumber: z.array(z.string()),
    status: z.string(),
    amountPaidWithSoliantTransactionFee: z.number(),
    soliantTransactionFeePaid: z.number(),
    paymentDate: z.number(),
});

export type CompanyPayment = z.infer<typeof companyPaymentSchema>;

export const fetchPayment = async (
    companyId : string,
    paymentId : string
) : Promise<CompanyPayment> => {
    try {
        const response = await fetch(
            `${baseUrl}/company/${companyId}/payment/${paymentId}`,
            {
                method: 'POST',
            }
        );
        const rawData : unknown = await response.json();

        if (!response.ok) {
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = companyPaymentSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        console.error('fetchInvoice error:', error);
        throw new Error(
            'Failed to get Payment. If this persists, please contact '
            + `${import.meta.env.VITE_APP_ACCOUNTS_RECEIVABLE_EMAIL}.`
        );
    }
};
