import {Card} from '@mui/material';
import SoliantContainer from '@/components/SoliantContainer';

const Maintenance = () : JSX.Element => {
    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                The payment site is currently offline for maintenance. Please check back in a few hours.
            </Card>
        </SoliantContainer>
    );
};

export default Maintenance;
