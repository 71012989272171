import {Card, Grid, Skeleton, Typography} from '@mui/material';
import {lazy, useEffect, useMemo, useState, Suspense} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import PaymentsMade from '@/components/PaymentsMade';
import SoliantContainer from '@/components/SoliantContainer';
import ErrorPage from '@/pages/ErrorPage';
import {hasTypeOfInvoice, useInvoiceQuery} from '@/utils/Invoice';
import {CARD, ECHECK} from '@/utils/PaymentTypes';
import {useQuoteQuery} from '@/utils/Quote';
import {formatAsCurrency} from '@/utils/Utils';

const PaymentCard = lazy(async () => import ('@/components/PaymentCard'));

const Loading = () => (<>
    <Skeleton variant="text" height={100}/>
    <Skeleton variant="rectangular" height={200}/>
    <Skeleton variant="text" height={100}/>
</>);

type Props = {
    billType : string;
};

const Payment = ({billType = 'invoice'} : Props) : JSX.Element => {
    const [paymentMethod, setPaymentMethod] = useState(ECHECK);
    const {companyId, billId} = useParams();
    const navigate = useNavigate();

    if (!companyId || !billId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    const {isLoading, error, data: bill}
        = billType === 'invoice'
            ? useInvoiceQuery(companyId, billId)
            : useQuoteQuery(companyId, billId);

    useEffect(() => {
        if (bill && bill.amountDue < 1) {
            navigate(`/invoice/${companyId}/${billId}/confirm`);
        }
    }, [bill]);

    if (error) {
        return <ErrorPage error={error}/>;
    }

    const returnUrl = useMemo(() => {
        if (bill) {
            const baseUrl = import.meta.env.VITE_APP_BASE_URL;
            const billUuidForStripe = hasTypeOfInvoice(bill) ? bill.invoiceUuidForStripe : bill.quoteUuidForStripe;
            const billType = hasTypeOfInvoice(bill) ? 'invoice' : 'quote';
            return `${baseUrl}/${billType}/${bill.companyUuidForStripe}/${billUuidForStripe}/accept`;
        }

        return '';
    }, [bill]);

    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                {isLoading || !bill
                    ? <>
                        <Skeleton variant="text" height={100}/>
                        <Skeleton variant="rectangular" height={200}/>
                    </>
                    : <>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{mb: 1}}>
                                <Typography variant="h3" sx={{mb: 1}}>
                                    {formatAsCurrency(
                                        paymentMethod === CARD
                                            ? bill.amountDueWithSoliantTransactionFee
                                            : bill.amountDue
                                    )}
                                </Typography>
                                {hasTypeOfInvoice(bill)
                                    ? <Typography variant="body1">
                                        Due {bill.dueDate.toLocaleDateString(
                                            'en-us',
                                            {year: 'numeric', month: 'long', day: 'numeric'}
                                        )}
                                    </Typography>
                                    : <></>
                                }
                            </Grid>
                            <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>To</Grid>
                            <Grid item xs={12} md={10}>{bill.companyName}</Grid>
                            <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>From</Grid>
                            <Grid item xs={12} md={10}>Soliant Consulting, Inc.</Grid>
                            <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Memo</Grid>
                            <Grid item xs={12} md={10}>
                                Thank you for your continued business.
                                If you have any questions, please call Accounts Receivable at 312-863-4302.
                            </Grid>
                        </Grid>
                        {bill.payments.length > 0
                            ? (
                                <Grid container sx={{pt: 2}}>
                                    <Grid item xs={12} sx={{color: 'text.secondary', pb: 1}}>Previous payments</Grid>
                                </Grid>
                            )
                            : (
                                <></>
                            )}
                        <PaymentsMade billType={billType} bill={bill}/>
                    </>
                }
            </Card>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                {
                    isLoading || !bill
                        ? <>
                            <Skeleton variant="text" height={100}/>
                            <Skeleton variant="rectangular" height={200}/>
                            <Skeleton variant="text" height={100}/>
                        </>
                        : bill.clientSecretForCard && bill.clientSecretForOther && <Suspense fallback={<Loading/>}>
                            <PaymentCard
                                amountDueWithSoliantTransactionFee={bill.amountDueWithSoliantTransactionFee}
                                amountDue={bill.amountDue}
                                returnUrl={returnUrl}
                                clientSecretForCard={bill.clientSecretForCard}
                                clientSecretForOther={bill.clientSecretForOther}
                                paymentMethod={paymentMethod}
                                setPaymentMethod={setPaymentMethod}
                            />
                        </Suspense>
                }
            </Card>
        </SoliantContainer>
    );
};

export default Payment;
