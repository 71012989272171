import {Card} from '@mui/material';
import AlertWithTitle from '@/components/AlertWithTitle';
import SoliantContainer from '@/components/SoliantContainer';
import ThemeComponent from '@/components/ThemeComponent';

type Props = {
    error : Error;
};

const ErrorPage = ({error} : Props) : JSX.Element => {
    return (
        <ThemeComponent>
            <SoliantContainer>
                <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                    <AlertWithTitle message={error.message}/>
                </Card>
            </SoliantContainer>
        </ThemeComponent>
    );
};

export default ErrorPage;
