import {Backdrop, CircularProgress} from '@mui/material';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ErrorPage from '@/pages/ErrorPage';
import type {Invoice} from '@/utils/Invoice';
import {fetchInvoice, useInvalidateInvoiceCache} from '@/utils/Invoice';
import type {Quote} from '@/utils/Quote';
import {fetchQuote, useInvalidateQuoteCache} from '@/utils/Quote';
import {convertFromFmToJsDate} from '@/utils/Utils';

type Props = {
    billType : string;
};

// Payment has allegedly been accepted by Stripe; now we need to:
// (1) confirm that it has indeed been accepted,
// (2) post the payment in tracker, and then
// (3) display PaymentConfirmation
const PaymentAccepted = ({billType = 'invoice'} : Props) : JSX.Element => {
    const {companyId, billId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const paymentIntentId = searchParams.get('payment_intent');
    const redirectStatus = searchParams.get('redirect_status');

    if (redirectStatus !== 'succeeded') {
        return <ErrorPage error={new Error('Payment intent did not succeed.')}/>;
    }

    if (!companyId || !billId || !paymentIntentId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    useEffect(() => {
        if (billType === 'invoice') {
            void useInvalidateInvoiceCache(queryClient, companyId, billId);
        } else {
            void useInvalidateQuoteCache(queryClient, companyId, billId);
        }
    }, [companyId, billId]);

    // Post the payment to tracker using the `accept` endpoint
    const {isLoading, error, data: bill} = useQuery(
        [`${billType}-accept`, companyId, billId, paymentIntentId],
        async () : Promise<Invoice | Quote> => {
            if (billType === 'invoice') {
                const invoice = await fetchInvoice(`/invoice/${companyId}/${billId}/accept/${paymentIntentId}`);
                return {
                    ...invoice,
                    dueDate: convertFromFmToJsDate(invoice.dueDate),
                };
            } else {
                const quote = await fetchQuote(`/quote/${companyId}/${billId}/accept/${paymentIntentId}`);
                return {
                    ...quote,
                    quoteDate: convertFromFmToJsDate(quote.quoteDate),
                };
            }
        }
    );

    if (error) {
        return <ErrorPage error={new Error(String(error))}/>;
    }

    if (isLoading || !bill) {
        return (
            <>
                <Backdrop
                    sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color='inherit'/>
                </Backdrop>
            </>
        );
    }

    // Display PaymentConfirmation
    navigate(`/${billType}/${companyId}/${billId}/confirm`, {replace: true});

    // This code should never display
    return <ErrorPage error={new Error('An unexpected error has occurred.')}/>;
};

export default PaymentAccepted;
