import {Container} from '@mui/system';
import SoliantHeader from '@/components/SoliantHeader';

type Props = {
    children : JSX.Element | JSX.Element[];
};

const SoliantContainer = ({children} : Props) : JSX.Element => {
    return (
        <Container maxWidth="md" sx={{
            pt: 2,
            pb: 2,
            pr: 0,
            pl: 0,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
        }}>
            <SoliantHeader/>
            {children}
        </Container>
    );
};

export default SoliantContainer;
