import {Card, Grid, Skeleton, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {useParams} from 'react-router';
import SoliantContainer from '@/components/SoliantContainer';
import ErrorPage from '@/pages/ErrorPage';
import type {CompanyPayment} from '@/utils/Company';
import {fetchPayment} from '@/utils/Company';
import {formatAsCurrency} from '@/utils/Utils';

const CompanyConfirmation = () : JSX.Element => {
    const {companyId, paymentId} = useParams();

    const {isLoading, error, data: payment} = useQuery<CompanyPayment | undefined, Error>(
        ['company-confirm', companyId, paymentId],
        async () => {
            if (companyId && paymentId) {
                return await fetchPayment(companyId, paymentId);
            }
        }
    );

    const clientPaymentStatus = useMemo(() => {
        if (!payment) {
            return '';
        }

        switch (payment.status) {
            case 'succeeded':
                return 'Succeeded';
            case 'failed':
            case 'requires_payment_method':
                return 'Failed';
            default:
                return 'Proccessing';
        }
    }, [payment]);

    if (!companyId || !paymentId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    if (error) {
        return <ErrorPage error={error}/>;
    }

    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                {
                    isLoading || !payment
                        ? <>
                            <Skeleton variant="text" height={100}/>
                            <Skeleton variant="rectangular" height={150}/>
                        </>
                        : <>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sx={{pb: 1}}>
                                    <Typography variant="h6">Payment {clientPaymentStatus}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        {formatAsCurrency(payment.amountPaidWithSoliantTransactionFee)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{pt: 1}}>
                                    <Typography variant="body2">
                                        Includes {formatAsCurrency(payment.soliantTransactionFeePaid)} processing fee
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{pt: 2}}>
                                <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Invoice #</Grid>
                                <Grid item xs={12} md={10}>
                                    {payment.invoiceNumber.join(', ')}
                                </Grid>
                                <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Payment date</Grid>
                                <Grid item xs={12} md={10}>
                                    {(new Date(payment.paymentDate * 1000)).toLocaleDateString()}
                                </Grid>
                                <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Payment amount</Grid>
                                <Grid item xs={12} md={10}>
                                    {formatAsCurrency(payment.amountPaidWithSoliantTransactionFee)}
                                </Grid>
                                <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>Processing fee</Grid>
                                <Grid item xs={12} md={10}>
                                    {formatAsCurrency(payment.soliantTransactionFeePaid)}
                                </Grid>
                            </Grid>
                        </>
                }
            </Card>
        </SoliantContainer>
    );
};

export default CompanyConfirmation;
