import {Alert, AlertTitle} from '@mui/material';

type Props = {
    heading ?: string;
    message : string;
};

const AlertWithTitle = ({heading, message} : Props) : JSX.Element => (
    <Alert severity="error">
        <AlertTitle>{heading ?? 'Error'}</AlertTitle>
        {message}
    </Alert>
);

export default AlertWithTitle;
