import {Card, Skeleton} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {lazy, Suspense, useState} from 'react';
import {useParams} from 'react-router';
import SoliantContainer from '@/components/SoliantContainer';
import ErrorPage from '@/pages/ErrorPage';
import type {Company} from '@/utils/Company';
import {fetchCompany} from '@/utils/Company';

const MaxStep = 1;

const PaySelectedInvoicesForm = lazy(async () => import ('@/forms/PaySelectedInvoicesForm'));
const SelectInvoicesForm = lazy(async () => import ('@/forms/SelectInvoicesForm'));

const Loading = () => {
    return (<Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
        <Skeleton variant="text" height={100}/>
        <Skeleton variant="rectangular" height={150}/>
    </Card>);
};

const CompanyInvoices = () : JSX.Element => {
    const {companyId} = useParams();
    const [step, setStep] = useState<number>(0);
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

    const nextStep = () => {
        if (step < MaxStep) {
            setStep(step + 1);
        }
    };

    const previousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    if (!companyId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    const {isLoading, error, data: company} = useQuery<Company, Error>(
        ['company', companyId],
        async () : Promise<Company> => {
            if (!companyId) {
                throw new Error('useInvoiceQuery: Missing required ID');
            }

            const company = await fetchCompany(companyId);

            return {
                name: company.name,
                invoices: company.invoices.filter(i => i.totalDue > 0),
                quotes: company.quotes.filter(q => q.totalDue > 0),
            };
        }
    );

    const toggleId = (ids : string | string[]) : void => {
        let invoices = [...selectedInvoices];

        if (typeof ids === 'string') {
            ids = [ids];
        }

        for (const id of ids) {
            const index = invoices.indexOf(id);

            if (index > -1) {
                invoices = invoices.filter(i => i !== id);
            } else {
                invoices.push(id);
            }
        }

        setSelectedInvoices(invoices);
    };

    if (error) {
        return <ErrorPage error={error}/>;
    }

    const ready = !isLoading && company;

    if (!ready) {
        return (<SoliantContainer>
            <Loading/>
        </SoliantContainer>);
    }

    return (
        <SoliantContainer>
            {step === 0
                ? <Suspense fallback={<Loading/>}>
                    <SelectInvoicesForm
                        company={company}
                        toggleId={toggleId}
                        selectedInvoices={selectedInvoices}
                        companyStripeId={companyId}
                        nextStep={nextStep}
                        setSelectedInvoices={setSelectedInvoices}
                    />
                </Suspense>
                : <Suspense fallback={<Loading/>}>
                    <PaySelectedInvoicesForm
                        company={company}
                        companyStripeId={companyId}
                        selectedInvoices={selectedInvoices}
                        previousStep={previousStep}
                    />
                </Suspense>}
        </SoliantContainer>
    );
};

export default CompanyInvoices;
