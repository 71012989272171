import {Card} from '@mui/material';
import SoliantContainer from '@/components/SoliantContainer';

const Home = () : JSX.Element => {
    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                Please pay your amount due using the 'pay' link in your invoice PDF
            </Card>
        </SoliantContainer>
    );
};

export default Home;
