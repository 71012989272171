import {Backdrop, CircularProgress} from '@mui/material';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ErrorPage from '@/pages/ErrorPage';
import type {CompanyAccept} from '@/utils/Company';
import {fetchAcceptCompanySetupIntents} from '@/utils/Company';

const CompanyAccepted = () : JSX.Element => {
    const {companyId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const paymentIntentId = searchParams.get('payment_intent');
    const redirectStatus = searchParams.get('redirect_status');

    // Post the payment to tracker using the `accept` endpoint
    const {isLoading, error, data: status} = useQuery(
        ['company-accept', companyId, paymentIntentId],
        async () : Promise<CompanyAccept | undefined> => {
            if (companyId && paymentIntentId) {
                return await fetchAcceptCompanySetupIntents(companyId, paymentIntentId);
            }
        }
    );

    useEffect(() => {
        queryClient.invalidateQueries(['company', companyId])
            .finally(() => {
                console.info('cleared company cache for', companyId);
            });
    }, [companyId]);

    useEffect(() => {
        if (status && status.status === 'success' && companyId && status.payment) {
            navigate(`/company/${companyId}/confirm/${status.payment}`);
        }
    }, [status]);

    if (redirectStatus !== 'succeeded') {
        return <ErrorPage error={new Error('Setup intent did not succeed.  Please go back and try again.')}/>;
    }

    if (!companyId || !paymentIntentId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    if (error) {
        return <ErrorPage error={new Error(String(error))}/>;
    }

    if (isLoading || !status || status.status === 'success') {
        return (
            <>
                <Backdrop
                    sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color='inherit'/>
                </Backdrop>
            </>
        );
    }

    // This code should only display on reject
    return <ErrorPage error={new Error(status.errorMessage)}/>;
};

export default CompanyAccepted;
