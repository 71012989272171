import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles' {
    type Theme = {
        status : {
            danger : string;
        };
    };
    // allow configuration using `createTheme`
    type ThemeOptions = {
        status ?: {
            danger ?: string;
        };
    };
}

const theme = createTheme({
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#0570de',
                    fontWeight: 600,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#6E9FC0',
            light: '#fff',
            dark: '#000',
        },
        secondary: {
            main: '#d8b139',
            contrastText: '#4d4017',
        },
        text: {
            primary: '#000',
            secondary: '#555',
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: 'Nunito Sans, Roboto, sans-serif',
        h6: {
            fontWeight: 600,
        },
        button: {
            textTransform: 'none',
        },
    },

});

export default theme;
