import {Card, Grid, Skeleton, Typography} from '@mui/material';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import PaymentsMade from '@/components/PaymentsMade';
import SoliantContainer from '@/components/SoliantContainer';
import ErrorPage from '@/pages/ErrorPage';
import {useInvoiceQuery, hasTypeOfInvoice} from '@/utils/Invoice';
import {useQuoteQuery} from '@/utils/Quote';
import {formatAsCurrency, round} from '@/utils/Utils';

type Props = {
    billType : string;
};

const PaymentConfirmation = ({billType = 'invoice'} : Props) : JSX.Element => {
    // console.log('billType:', billType);
    const navigate = useNavigate();
    const {companyId, billId} = useParams();

    if (!companyId || !billId) {
        return <ErrorPage error={new Error('Missing required id.')}/>;
    }

    const {isLoading, error, data: bill}
        = billType === 'invoice'
            ? useInvoiceQuery(companyId, billId)
            : useQuoteQuery(companyId, billId);

    if (error) {
        return <ErrorPage error={error}/>;
    }

    if (!isLoading && bill) {
        if (round(bill.amountDue, 2) > 0) {
            navigate(`/${billType}/${companyId}/${billId}`, {replace: true});
        }
    }

    // console.log('bill:', bill);
    const billTypeCapitalized = billType[0].toUpperCase() + billType.substring(1);
    return (
        <SoliantContainer>
            <Card raised={true} square={false} variant="elevation" sx={{m: 2, p: 2}}>
                {
                    isLoading || !bill
                        ? <>
                            <Skeleton variant="text" height={100}/>
                            <Skeleton variant="rectangular" height={150}/>
                        </>
                        : <>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sx={{pb: 1}}>
                                    <Typography variant="h6">{billTypeCapitalized} paid</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        {formatAsCurrency(bill.amountPaidWithSoliantTransactionFee)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{pt: 1}}>
                                    <Typography variant="body2">
                                        Includes {formatAsCurrency(bill.soliantTransactionFeePaid)} processing fee
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{pt: 2}}>
                                <Grid item xs={12} md={2} sx={{color: 'text.secondary'}}>{billTypeCapitalized} #</Grid>
                                <Grid item xs={12} md={10}>
                                    {hasTypeOfInvoice(bill) ? bill.invoiceNumber : bill.quoteNumber}
                                </Grid>
                            </Grid>
                            <PaymentsMade billType={billType} bill={bill}/>
                        </>
                }
            </Card>
        </SoliantContainer>
    );
};

export default PaymentConfirmation;
