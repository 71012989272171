// import Typography from '@mui/material/Typography';
import {SnackbarProvider} from 'notistack';
import {Outlet} from 'react-router-dom';
import ThemeComponent from '@/components/ThemeComponent';

const Layout = () : JSX.Element => {
    return (
        <>
            <SnackbarProvider>
                <ThemeComponent>
                    <Outlet/>
                </ThemeComponent>
            </SnackbarProvider>
        </>
    );
};

export default Layout;
