import Typography from '@mui/material/Typography';
import classes from './SoliantHeader.module.scss';
import favicon from '@/images/soliant_favicon.svg';

const SoliantHeader = () : JSX.Element => {
    // console.log('classes:', classes);
    return (
        <>
            <Typography variant="body1" sx={{color: 'white', m: 2, pl: 2}}>
                <img src={favicon} className={classes.soliantLogo}/>
                Soliant Consulting, Inc.
            </Typography>
        </>
    );
};

export default SoliantHeader;
